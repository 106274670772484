import { localizedString, LocalizedString } from "core/localization/constants/remote-config"

export interface RemoteConfigWelcomeBigDetailedPage {
  quizWelcomeBigDetailedPage: {
    button: LocalizedString,

    main: {
      title: LocalizedString,
      description: LocalizedString[]
    }

    about: {
      title: LocalizedString,
      description: LocalizedString[]
    }
    linguistic: {
      title: LocalizedString,
      description: LocalizedString[]
    }
    logical: {
      title: LocalizedString,
      description: LocalizedString[]
    }
    spatial: {
      title: LocalizedString,
      description: LocalizedString[]
    }
    musical: {
      title: LocalizedString,
      description: LocalizedString[]
    } 
    bodily: {
      title: LocalizedString,
      description: LocalizedString[]
    } 
    interpersonal: {
      title: LocalizedString,
      description: LocalizedString[]
    }
    intrapersonal: {
      title: LocalizedString,
      description: LocalizedString[]
    }
    naturalistic: {
      title: LocalizedString,
      description: LocalizedString[]
    }
    about2: {
      title: LocalizedString,
      description: LocalizedString[]
    }
    about3: {
      title: LocalizedString,
      description: LocalizedString[]
    }
    review: {
      title: LocalizedString,
      description: LocalizedString[],
      name: LocalizedString,
      age: LocalizedString
    }

    discover: {
      title: LocalizedString,
      description: LocalizedString,
      button: LocalizedString
    }
  }
}

export const INITIAL_CONFIG_WELCOME_BIG_DETAILED_PAGE: RemoteConfigWelcomeBigDetailedPage = {
  quizWelcomeBigDetailedPage: {
    main: {
      title: localizedString('Unlock Your Hidden Genius: Discover and Improve Your Intelligence'),
      description: [localizedString('Intelligence is more than just IQ scores or academic success - it’s the foundation of how we navigate the world. It affects the way we solve problems, adapt to new situations, and communicate with others. In both personal and professional life, intelligence shapes our decision-making, creativity, and ability to overcome challenges.'),
         localizedString('Many people believe that intelligence is something you’re born with and cannot change - but that’s a myth. The truth is, intelligence is not fixed. You can improve it by identifying your strengths and working on areas where you need growth. The key is understanding which type of intelligence you excel in and which one needs development.'), 
         localizedString("<b>Brainary's IQ test</b> is the perfect way to discover your unique intelligence profile. Once you understand your strengths, you can focus on improving the skills that will open new opportunities in your personal and professional life.")]
    },

    button: localizedString('Start Certified Test'),

    about: {
      title: localizedString('The 8 Types of Intelligence'),
      description: [localizedString('The concept of multiple intelligences was introduced by the renowned psychologist Howard Gardner in 1983. In his groundbreaking book, Frames of Mind: The Theory of Multiple Intelligences, Gardner challenged the long-held belief that intelligence was a single, measurable factor - such as IQ. Instead, he proposed that intelligence is diverse, dynamic, and can be developed throughout life.'),
        localizedString('Understanding these different types of intelligence can help you unlock your full potential by focusing on the areas where you naturally excel while also improving the ones that need development. Below are the eight types of intelligence that shape the way we think, learn, and grow:')],
    },

    linguistic: {
      title: localizedString('Linguistic Intelligence'),
      description: [localizedString('This is the ability to express thoughts and ideas effectively through words, both spoken and written. People with high linguistic intelligence excel in storytelling, public speaking, and writing. They have a strong grasp of language and enjoy reading, debating, and learning new words.'),
        localizedString('<i>Most likely career choices as:</i> <b>Writers, journalists, poets, lawyers, and public speakers.</b>')],
    },
    logical: {
      title: localizedString('Logical-Mathematical Intelligence'),
      description: [localizedString('This means being really good at solving puzzles, math problems, and figuring out how things work. People who possess this intelligence can quickly recognize patterns, analyze complex problems, and think logically. They enjoy working with numbers, formulas, and abstract ideas.'),
        localizedString('<i>Most likely career choices as:</i> <b>Scientists, engineers, programmers, and data analysts.</b>')],
    },
    spatial: {
      title: localizedString('Spatial Intelligence'),
      description: [localizedString('This type of intelligence allows people to visualize and manipulate objects in their minds. Those with strong spatial intelligence have a keen eye for design, proportions, and details. They often excel in fields that require creativity and imagination, such as architecture or visual arts.'),
        localizedString('<i>Most likely career choices as:</i> <b>Architects, graphic designers, photographers, and artists.</b>')],
    },
    musical: {
      title: localizedString('Musical Intelligence'),
      description: [localizedString('Musical intelligence is the ability to understand, create, and appreciate music. People with this intelligence can recognize rhythms, tones, and harmonies with ease. They often have a deep emotional connection to music and might play instruments, compose songs, or analyze melodies naturally.'),
        localizedString('<i>Most likely career choices as:</i> <b>Musicians, composers, sound engineers, and music teachers.</b>')],
    },
    bodily: {
      title: localizedString('Bodily-Kinesthetic Intelligence'),
      description: [localizedString('This intelligence involves using one’s body effectively for movement, control, and coordination. People with high bodily-kinesthetic intelligence are skilled at physical activities, whether it’s sports, dancing, or fine motor skills like surgery.'),
        localizedString('<i>Most likely career choices as:</i> <b>Athletes, dancers, actors, and surgeons.</b>')],
    },
    interpersonal: {
      title: localizedString('Interpersonal Intelligence'),
      description: [localizedString('People with interpersonal intelligence excel at understanding and interacting with others. They are empathetic, great communicators, and often work well in team settings. This intelligence is key for leadership, teaching, and professions that involve social interaction.'),
        localizedString('<i>Most likely career choices as:</i> <b>Teachers, psychologists, managers, and diplomats.</b>')],
    },
    intrapersonal: {
      title: localizedString('Intrapersonal Intelligence'),
      description: [localizedString('Intrapersonal intelligence is about deep self-awareness and emotional understanding. People with this intelligence have a strong sense of their own emotions, strengths, and weaknesses. They are reflective and often enjoy personal growth and introspection.'),
        localizedString('<i>Most likely career choices as:</i> <b>Philosophers, writers, therapists, and life coaches.</b>')],
    },
    naturalistic: {
      title: localizedString('Naturalistic Intelligence'),
      description: [localizedString('This intelligence involves a deep connection with nature and the environment. People with naturalistic intelligence have a strong appreciation for plants, animals, and ecosystems. They tend to notice patterns in nature and feel most fulfilled when working outdoors.'),
        localizedString('<i>Most likely career choices as:</i> <b>Biologists, farmers, environmentalists, and conservationists.</b>')],
    },

    about2: {
      title: localizedString('Find Your Type of Intelligence with Brainary'),
      description: [localizedString('Intelligence isn’t just about being good at math or memorizing facts - it’s about understanding your unique strengths and how you can use them to succeed. Everyone has different types of intelligence, and the key to growth is identifying yours.'),
        localizedString('If you’ve ever wondered what makes you naturally talented in certain areas while struggling in others, <b>Brainary</b> can help you find the answer.')],
    },
    about3: {
      title: localizedString('What is <b>Brainary</b>?'),
      description: [localizedString('<b>Brainary</b> is a powerful tool designed to help individuals identify and enhance their intelligence types. Through engaging and insightful tests, <b>Brainary</b> evaluates various cognitive abilities such as problem-solving, creativity, and logical thinking.'),
        localizedString('But <b>Brainary</b> doesn’t just stop at self-discovery - it provides you with a valuable certificate that can showcase your strengths to employers and clients. Whether you’re looking to advance in your career, switch fields, or gain confidence in your abilities, <b>Brainary</b> is your key to unlocking new opportunities.')],
    },
    review: {
      title: localizedString('How Brainary Changed the Life'),
      description: [localizedString('I used to work in a factory - long hours, exhausting shifts, and no real sense of progress. Every day felt the same, and I thought I had no other options. Then, I took the <b>Brainary</b> test. It revealed that my strengths were in logical and linguistic intelligence - something I had never fully realized. That knowledge gave me the confidence to take action.'),
        localizedString('With my <b>Brainary</b> certificate, I started applying for freelance work. Soon, I landed my first client, then another. Now, I work for myself, choosing my projects and earning more than I ever did at my old job. Looking back, I can’t believe I almost stayed stuck. Understanding my intelligence changed everything - and it can for you too!')],
      name: localizedString('Peter Smith'),
      age: localizedString('32 y.o.')
    },

    discover: {
      title: localizedString("Discover Your Intelligence – Take the Test!"),
      description: localizedString('Everyone has unique strengths waiting to be unlocked. The first step to success is understanding your own intelligence and using it to your advantage. Take the IQ test today and  receive your <b>Brainary’s</b> IQ Certificate. Your future is in your hands!'),
      button: localizedString('Check your real intelligence level now')
    },
  }
}
