import { TypedUseSelectorHook, useSelector } from "react-redux";

import { BASE_QUIZ } from "@web-solutions/base-app/constants/routes"

import { RemoteConfig } from "@web-solutions/core/constants/remote-config";
import { LocaleState } from "@web-solutions/core/localization/store";
import { BillingState } from "@web-solutions/core/store/billing";
import { QuizState } from "@web-solutions/core/store/quiz";
import { UpsaleState } from "@web-solutions/core/upsale/store";
import { VideoState } from "@web-solutions/core/video/store";
import { TakePhotoState } from "@web-solutions/core/take-photo/store";

export interface RoutingState {
  isCompleted: boolean;
  currentStep: number;
  startRoute: string | null;
}


export interface AppState {
  paymentProject: string;
  loaded: boolean;
  pending: boolean;
  appLink: string;
}

export interface ApiAuth {
  token_type: null | string;
  access_token: null | string;
}

export interface ProfileState {
  authorized: boolean;
  apiAuth: ApiAuth;
  email: string;
  gender: string | null;
  age: string | null;
  relationship_status: string | null;

  is_unsubscriber: boolean | null | number;
}

export const LOCAL_QUIZ = {
  WELCOME: 'WELCOME',
  WELCOME_IMAGE: 'WELCOME_IMAGE',
  WELCOME_GENDER: 'WELCOME_GENDER',
  WELCOME_GENDER_DETAILED: 'WELCOME_GENDER_DETAILED',
  WELCOME_DETAILED: 'WELCOME_DETAILED',
  WELCOME_BIG_DETAILED: 'WELCOME_BIG_DETAILED',
  MAGIC: 'MAGIC',
  GENDER: 'GENDER',
  QUESTION_USERS_USING:'QUESTION_USERS_USING',
  CORRECT: 'CORRECT',
  EMAIL_ACHIEVEMENTS: 'EMAIL_ACHIEVEMENTS',
  GRAPH_PLAN: 'GRAPH_PLAN'
} as const;

export const QUIZ = {
  ...BASE_QUIZ,
  ...LOCAL_QUIZ,
} as const;


export interface RootStatePackages {
  app: AppState;
  remoteConfig: RemoteConfig;
  routing: RoutingState;
  billing: BillingState;
  profile: ProfileState;
  quiz: QuizState;
  upsale: UpsaleState;
  locale: LocaleState;
  video: VideoState;
  takePhoto: TakePhotoState;
}

export const useAppSelector: TypedUseSelectorHook<RootStatePackages> =
  useSelector;
