import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { number } from "prop-types";

export type SelectedOptionPayload = {
  id?: string;
  label: string;
  emoji: string;
};

export type QuizAnswerPayload = {
  questionTitle?: string;
  answers: SelectedOptionPayload[];
  questionId?: string;
};

interface SelectedAnswersIQ {
  answerId: string;
  isCorrect: boolean;
  counted: boolean;
}

interface Time {
  start: number,
  end: number
}

export interface QuizState {
  selectedAnswers: Record<string, QuizAnswerPayload>;
  correctAnswersCount: number;
  selectedAnswersIQ: Record<string, SelectedAnswersIQ>;
  time: Time
}

const defaultTime = {
  start: 0,
  end: 0
}

const initialState: QuizState = {
  selectedAnswers: {},
  selectedAnswersIQ: {},
  correctAnswersCount: 0,
  time: defaultTime
};

const slice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    setSelectedAnswers: (state, action: PayloadAction<QuizAnswerPayload>) => {
      const { questionId, questionTitle, answers } = action.payload;

      state.selectedAnswers[questionId!] = {
        questionTitle,
        answers,
      };
    },
    setSelectedAnswersIQ: (
      state,
      action: PayloadAction<{
        questionId: string;
        answerId: string;
        isCorrect: boolean;
      }>,
    ) => {
      const { questionId, answerId, isCorrect } = action.payload;

      if (state.selectedAnswersIQ[questionId]) {
        state.selectedAnswersIQ[questionId].answerId = answerId;

        if (isCorrect && !state.selectedAnswersIQ[questionId].counted) {
          state.correctAnswersCount += 1;
          state.selectedAnswersIQ[questionId].counted = true;
        }
      } else {
        state.selectedAnswersIQ[questionId] = {
          answerId,
          isCorrect,
          counted: isCorrect,
        };

        if (isCorrect) {
          state.correctAnswersCount += 1;
        }
      }
    },
    setTime: (state, action: PayloadAction<keyof Time>) => {
      const key = action.payload
      state.time[key] = Date.now()
    },
  }
});

export const { setSelectedAnswersIQ, setTime } = slice.actions;

export { slice as quizSlice };

export type SelectedOptionAi = {
  id?: string;
  label: string;
};