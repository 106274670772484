import { localizedString, LocalizedString } from "core/localization/constants/remote-config"

export interface RemoteConfigGraphPlan {
  quizGraphPlanScreen: {
    title: LocalizedString,
    subtitle: LocalizedString,
    buttonText: LocalizedString
  }
}

export const INITIAL_CONFIG_GRAPH_PLAN: RemoteConfigGraphPlan = {
  quizGraphPlanScreen: {
    title: localizedString('Your 5-week AI-Driven Income Growth Challenge is ready!'),
    subtitle: localizedString("Your readiness level"),
    buttonText: localizedString('Continue')
  }
}