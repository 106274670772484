import { localizedString, LocalizedString } from "core/localization/constants/remote-config"

export interface RemoteConfigWelcomeGenderDetailed {
  quizWelcomeGenderDetailedPage: {
    title: LocalizedString,
    subtitle: LocalizedString,
    usersText: LocalizedString,
    genders: {
      title: LocalizedString,
      variants: {
        female: LocalizedString,
        male: LocalizedString,
        binary: LocalizedString
      }
    },
    trustedText: LocalizedString,
    score: {
      text: LocalizedString,
      iq: number
    }
  }
}

export const INITIAL_CONFIG_WELCOME_GENDER_DETAILED: RemoteConfigWelcomeGenderDetailed = {
  quizWelcomeGenderDetailedPage: {
    title: localizedString("Want to practice? Take Brainary <b>IQ Challenge</b>"),
    subtitle: localizedString("Your future improvement starts here - take the test and uncover your potential."),
    usersText: localizedString("<b>762</b> verified users"),
    genders: {
      title: localizedString('Select your gender to start'),
      variants: {
        female: localizedString('Female'),
        male: localizedString('Male'),
        binary: localizedString('Enby')
      }
    },
    trustedText: localizedString('Trusted By Cambridge University'),
    score: {
      text: localizedString('<b>Jennifer</b> just got her result!'),
      iq: 101
    }
  }
}