import _merge from 'lodash/merge';
import _forEach from 'lodash/forEach';
import i18next, { Resource, TOptions } from 'i18next';
import { Trans, TransProps } from 'react-i18next';
//@ts-ignore
import interpolate from 'interpolate';
import dayjs from 'dayjs';

export type LString = string | {
  en: string,
  de?: string,
  es?: string,
  fr?: string,
  pt?: string,
  ja?: string,
  ar?: string,
  'es-mx'?: string,
  'pt-br'?: string,
}

require('dayjs/locale/de')
require('dayjs/locale/es-mx')
require('dayjs/locale/es')
require('dayjs/locale/fr')
require('dayjs/locale/pt-br')
require('dayjs/locale/pt')
require('dayjs/locale/ja')
require('dayjs/locale/ar')

const resources: Resource = {
  de: {
    translation: require('./locales/de/index.json'),
  },
  en: {
    translation: _merge({}, require('./locales/en/index.json'), require('./locales/experimental/en.json')),
  },
  'es-mx': {
    translation: require('./locales/es-MX/index.json'),
  },
  es: {
    translation: require('./locales/es-ES/index.json'),
  },
  fr: {
    translation: require('./locales/fr/index.json'),
  },
  'pt-br': {
    translation: require('./locales/pt-BR/index.json'),
  },
  pt: {
    translation: require('./locales/pt-PT/index.json'),
  },
  ja: {
    translation: require('./locales/ja/index.json'),
  },
  ar: {
    translation: require('./locales/ar/index.json'),
  },
};

let globalOptions: object | undefined;

const Localization = {
  init: function (localization: Resource, tOptions?: object, cfgOptions?: { lng?: string, postProcess?: string[], excludeCore?: boolean }) {
    globalOptions = tOptions;
    localization = _merge({}, localization);

    if (!cfgOptions?.excludeCore) {
      _forEach(localization, (v, k) => {
        localization[k] = _merge(resources[k], localization[k]);
      });
    }

    let lng = (cfgOptions?.lng || navigator.language || 'en').replace('_', '-').toLowerCase();

    if (!localization[lng]) {
      lng = lng.split('-')[0];
    }
    if (!localization[lng]) {
      lng = 'en';
    }

    dayjs.locale(lng);

    document.documentElement.lang = lng;

    i18next
      .init<null>({
        lng,
        fallbackLng: 'en',
        resources: localization as Resource,
        lowerCaseLng: true,
        react: {
          transEmptyNodeValue: '', // what to return for empty Trans
          transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
          transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b', 's', 'a'],
        },
        postProcess: cfgOptions?.postProcess,
      });
  },
}

export default Localization;

function processOptions(options?: object) {
  return Object.assign({}, globalOptions, options);
}

export function t(key: string, options?: object & TOptions): string {
  return i18next.t(key, processOptions(options)) as unknown as string;
}

export const pickKey = (key?: LString): string => {
  return typeof key === 'string'
    ? key
    // @ts-ignore
    : key?.[i18next.language] || key?.[i18next.language.split('-')[0]] || '';
}

export function tm(lngs: LString | undefined, defaultKey: string, options?: object & TOptions): string {
  return (
    interpolate(
      pickKey(lngs || undefined),
      processOptions(options)
    )
    ||
    i18next.t(defaultKey, processOptions(options))
  );
}

type TProps = Omit<TransProps<any>, "i18n" | "i18nKey">


export const T = ({ k, tOptions, ...other }: { k: string, } & TProps) => {
  return (
    <Trans
      i18n={i18next}
      i18nKey={k}
      tOptions={processOptions(tOptions)}
      {...other}
    />
  )
}

export const TM = ({ k, defaultKey = '', tOptions, ...other }: { k?: LString, defaultKey?: string } & TProps) => {
  return (
    <T
      k={interpolate(pickKey(k), processOptions(tOptions)) || defaultKey}
      tOptions={processOptions(tOptions)}
      {...other}
    />
  )
}

