export const sendEmail = async (email: string, templateName: string) => {

  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      template: templateName,
    })
  };
  
  const response = await fetch('https://dev.myjoygames.com/smtp-email-send', options)

  return response.json();
};

export const sendEmailWithDynamicData = async (
  email: string,
  templateName: string,
  variables: Record<string, any>
) => {
  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      template: templateName,
      variables,
    })
  };
  
  const response = await fetch('https://dev.myjoygames.com/smtp-email-send', options)

  return response.json();
};
