import { FloatButton } from "antd";

import DeviceProps from '@magnus/react-native-device-props';
import { sendEmailWithDynamicData } from "core/upsale/api/send-email";


const IdfmButton = () => {
    const onClick = async () => {
        const email = prompt('Введите email:');
        const idfm = await DeviceProps.getIDFM();
        if (email) await sendEmailWithDynamicData(email, `idfm`, { idfm })
    }

    return <FloatButton
        onClick={onClick}
        description='idfm'
        shape="square"
    />
}

export default IdfmButton



