import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import classNames from "classnames";

import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";

import { UserData } from "@web-solutions/base-app/api/soula-api/types";
import { createUser } from "@web-solutions/base-app/api/soula-api";

import { SOLIDGATE_ERROR_CODES } from "@web-solutions/core/constants/errors";
import type { PaymentError } from "@web-solutions/core/interfaces/errors";

import type { SubscribeOnOneClickParams } from "@web-solutions/core/store/billing/types";
import Analytics from "@web-solutions/module-analytics";

import { PaymentSystem, Provider } from "@web-solutions/react-billing";

import { useAppSelector } from "@web-solutions/store/types";

import { useNavigateAfterPurchase } from '@web-solutions/base-app/hooks/use-navigate-after-purchase';

import ApplePay from "core/payment/components/apple-pay";

import PaymentForm from "core/payment/components/payment-form";

import { ModeApplePay, ModeGooglePay } from "core/constants/billing";
import { EVENT_ACTION } from "core/constants/general";

import { useRemoteConfig } from "core/hooks/use-remote-config";
import {
  useShowApplePay,
  useShowGooglePay,
  useShowMercadoPago,
  useShowPayPal,
} from "core/hooks/use-show-payment-method";
import {
  OrderDetails,
  PaddleConfig,
  type Subscription,
} from "core/interfaces/billing";
import type { PaymentModalTitle } from "core/interfaces/payment-modal";

import BasePaymentModal from "core/payment/base";
import ThreeDSecure, {
  type RecurlyError,
} from "core/payment/components/threeDSecure";
import {
  applePayOrder,
  checkRedirectResults,
  createCustomer,
  handleErrorPurchase,
  handleSuccessPurchase,
  initOrders,
  setInjectedPaymentMethod,
  setIsPaymentOpen,
  setIsSubmitByCard,
  setLoading,
  setPostCode,
  subscribe,
  switchToReservePaymentSystem,
} from "core/store/billing/actions";

import {
  type ProductDetails,
  selectLoading,
  selectNoFundsOfferEndDate,
  selectPaddleConfig,
  selectPaymentSystem,
  selectPostcode,
  selectInjectedPaymentMethod,
} from "core/store/billing/selectors";
import { Preloader } from "core/ui-elements";

import { nextStep, setCurrentStep } from "core/upsale/store";

import { submitCancelSubscription } from "core/upsale/store/actions";

//@ts-ignore
import { handlePurchaseThunk } from "core/upsale/store/thunks/purchase";

//@ts-ignore
import { InjectedPaymentMethod } from "core/constants/remote-config";

import { processEmail } from "src/store/profile/actions";

import { ReactComponent as CreditCardIcon } from "./icons/credit-card.svg";
import { ReactComponent as LeftArrowIcon } from "./icons/chevron-left.svg";

import ErrorPopup from "./components/error-popup";
import {
  SubscribeOnOneClick,
  type SubscribeOnOneClickRef,
} from "./components/subscribe-on-one-click";

import { CardFormLayout } from "./constants";

import classes from "./style.module.scss";

export type Options = {
  orderDetails: OrderDetails | null;
  config: {
    email: any;
    postcode: string;
  } & PaddleConfig;
  layout: CardFormLayout;
  applePayButtonParams: {
    color: "black" | "white" | "white-outline";
    enabled: boolean;
    disabled: boolean;
    containerId: string;
  };
  googlePayButtonParams: {
    color: "black" | "white";
    type: string;
    mode: ModeGooglePay;
    disabled: boolean;
    containerId: string;
  };
  formParams: {
    autoFocus: boolean;
  };
};

export interface StandalonePaymentRef {
  showPaymentPopup: () => void;
  closePaymentPopup: () => void;
  processFormData: (data: any) => void;
  subscribeOnOneClick: (data: SubscribeOnOneClickParams) => void;
}

export interface StandalonePaymentProps {
  activeProduct: ProductDetails;
  fullMode?: boolean;
  shownByDefault?: boolean;
  orderDetails: OrderDetails | null;
  isOnlyBaseModal?: boolean;
  paymentModalTitle?: PaymentModalTitle;
  paymentModalSubtitle?: React.ReactNode;
  isOnlyCard?: boolean;
  isOnlyPayPal?: boolean;
  errorPopupWithPayPal?: boolean;
  hidePayPal?: boolean;
  onSuccess: (purchase: Subscription) => void;
  onError?: (error: any) => void;
  onClose?: () => void;
  onSubmit?: () => void;
  onSubscribeOnOneClickError?: (error: PaymentError) => void;
  isPurchaseProgress?: boolean;
  upsaleTrigger?: string;
  isUpsaleOneTimePayment?: boolean;
  onSaveTokenOneTime?: (tokenId: string) => void;
  oneTimeRecurlyId?: string;
  subscribeRecurlyId?: string;
  isOnlyOneTimePurshaseUpsale?: boolean;
  isRedirectToLastScreen?: boolean;
  // isPaymentOpen: boolean;
}

const APP_NAME = process.env.REACT_APP_APP_NAME;

export const StandalonePayment = React.forwardRef<
  StandalonePaymentRef,
  StandalonePaymentProps
>(
  (
    {
      activeProduct,
      fullMode,
      shownByDefault,
      orderDetails,
      paymentModalTitle,
      paymentModalSubtitle,
      isOnlyBaseModal,
      isOnlyCard,
      isOnlyPayPal,
      errorPopupWithPayPal,
      hidePayPal,
      onSuccess,
      onClose,
      onError,
      onSubmit,
      onSubscribeOnOneClickError,
      isPurchaseProgress,
      upsaleTrigger,
      onSaveTokenOneTime,
      isUpsaleOneTimePayment,
      isOnlyOneTimePurshaseUpsale,
      subscribeRecurlyId,
      oneTimeRecurlyId,
      isRedirectToLastScreen,
      // isPaymentOpen,
    },
    ref
  ) => {
    const dispatch = useDispatch<any>();

    const socRef = useRef<SubscribeOnOneClickRef>(null);

    const [isOpenPaymentPopup, setIsOpenPaymentPopup] =
      useState(!!shownByDefault);
    const [paymentData, setPaymentData] = useState<any>(null);
    const [tokenThreeDSecure, setTokenThreeDSecure] = useState(null);
    const [errorCode, setErrorCode] = useState<string>();

    const paddleConfig = useSelector(selectPaddleConfig);
    const { isPaymentOpen } = useAppSelector(state => state.billing);
    const postcode = useSelector(selectPostcode);
    const noFundsOfferEndDate = useSelector(selectNoFundsOfferEndDate);
    const isPending = useSelector(
      (state: any) => state.billing.pending || state.billing.orderPending
    );
    const isLoadingBilling = useSelector(selectLoading);
    const isLoadingApp = useSelector((state: any) => !state.app.loaded);
    //@ts-ignore
    const email = useSelector((state) => state.profile.email);
    //@ts-ignore
    const steps = useSelector((state) => state.upsale.steps);
    const selectedAnswers = useAppSelector(
      (state) => state.quiz.selectedAnswers
    );

    const [isOpenErrorPopup, setIsOpenErrorPopup] = useState(false);

    const {
      modeApplePay,
      modeGooglePay,
      modeCardForm,
      cardFormLayout,
      errorPopup,
      defaultInjectedPaymentMethod,
      isCvvTooltip,
      isInjectedPaymentMethodOnPage,
      noFundsOffer,
      //@ts-ignore
      cerebrumSubScreenData: { standalonePlan: { cardButton } },
    } = useRemoteConfig();

    const paymentSystem = useSelector(selectPaymentSystem);

    const showPayPal = useShowPayPal(activeProduct);
    const showApplePay = useShowApplePay();
    const showGooglePay = useShowGooglePay();
    const showMercadoPago =
      useShowMercadoPago() && !activeProduct.isOneTimePurchase;

    useEffect(() => {
      if (!isLoadingBilling) {
        dispatch(checkRedirectResults()).then(
          (p: Subscription | null) => {
            if (p) {
              handlePaymentSuccess(p);
            }
          },
          (error: any) => {
            Analytics.trackEvent("modal_payment", "error", {
              message: error?.message,
              paymentSystem: error?.paymentSystem,
            });
          }
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingBilling]);

    const options: Options = useMemo(
      () => ({
        orderDetails,
        config: Object.assign({ email, postcode }, paddleConfig),
        layout: cardFormLayout,
        applePayButtonParams: {
          color: "black",
          // enabled: !process.env.REACT_APP_APPLE_PAY_H2H,
          // mode: modeApplePay,
          enabled: true,
          mode: ModeApplePay.ENABLED,
          disabled:
            !email &&
            paymentSystem !== PaymentSystem.SOLIDGATE &&
            paymentSystem !== PaymentSystem.PADDLE,
          containerId: "container-solidgate-apple-pay",
        },
        googlePayButtonParams: {
          color: "black",
          type: "plain",
          mode: modeGooglePay,
          disabled:
            !email &&
            paymentSystem !== PaymentSystem.SOLIDGATE &&
            paymentSystem !== PaymentSystem.PADDLE,
          containerId: "container-solidgate-google-pay",
        },
        formParams: {
          autoFocus: false,
        },
        isCvvTooltip,
      }),
      [
        orderDetails,
        email,
        postcode,
        paddleConfig,
        cardFormLayout,
        paymentSystem,
        modeApplePay,
        modeGooglePay,
        isCvvTooltip,
      ]
    );

    const handleClosePaymentPopup = useCallback(() => {
      Analytics.trackEvent("modal_payment", EVENT_ACTION.CLOSE);
      setIsOpenPaymentPopup(false);
      onClose && onClose();
      if (isInjectedPaymentMethodOnPage) {
        dispatch(setInjectedPaymentMethod(defaultInjectedPaymentMethod));
      }
    }, [
      defaultInjectedPaymentMethod,
      dispatch,
      isInjectedPaymentMethodOnPage,
      onClose,
    ]);

    const handlePaymentSuccess = useCallback(
      async (purchase: Subscription) => {
        Analytics.trackEvent("modal_payment", "success");
        onSuccess(purchase);
      },
      [onSuccess]
    );

    const handleCardPaymentSuccess = useCallback(
      async (purchase: Subscription) => {
        if (purchase.paymentSystem === PaymentSystem.PADDLE) {
          const em = purchase.email || email;
          dispatch(processEmail(em));
          // we do not handle purchase here because purchase is handled at success-url
        } else {
          if (!activeProduct.isOneTimePurchase) {
            dispatch(subscribe({ subscription_id: purchase.transaction_id }));
          }
          await dispatch(
            handleSuccessPurchase({
              ...purchase,
              isOneTimePurchase: activeProduct?.isOneTimePurchase,
              price_id: activeProduct?.id,
            })
          );
          setIsOpenPaymentPopup(false);
          setTimeout(() => {
            handlePaymentSuccess(purchase);
          }, 1);
        }
      },
      [
        activeProduct?.id,
        activeProduct?.isOneTimePurchase,
        dispatch,
        email,
        handlePaymentSuccess,
      ]
    );

    const handleCardPaymentError = useCallback(
      (error: PaymentError) => {
        Analytics.trackEvent("modal_payment", "error", {
          message: error?.message,
          paymentSystem: error?.paymentSystem,
        });

        const isInsufficientFundsErrorCode =
          error?.code === SOLIDGATE_ERROR_CODES.INSUFFICIENT_FUNDS;
        const isSONoFundsTriggered =
          isInsufficientFundsErrorCode &&
          noFundsOffer.enabled &&
          !noFundsOfferEndDate &&
          error?.entity === "form";

        if (!activeProduct?.isOneTimePurchase && isSONoFundsTriggered) {
          setIsOpenPaymentPopup(false);

          dispatch(handleErrorPurchase(error, { noToast: true }));

          if (error?.paymentSystem === PaymentSystem.SOLIDGATE) {
            dispatch(initOrders());
          }

          onError && onError(error);
          return;
        }

        let noToast;
        setErrorCode(error?.code);
        if (!activeProduct?.isOneTimePurchase && errorPopup?.enabled) {
          Analytics.trackEvent("error_popup", "open", {
            message: error?.message,
            paymentSystem: error?.paymentSystem,
          });
          setIsOpenPaymentPopup(false);
          setIsOpenErrorPopup(true);
          if (!isInsufficientFundsErrorCode) {
            dispatch(switchToReservePaymentSystem());
          }
          noToast = true;
        }
        dispatch(handleErrorPurchase(error, { noToast }));
        if (error?.paymentSystem === PaymentSystem.SOLIDGATE) {
          dispatch(initOrders());
        }

        onError && onError(error);
      },
      [
        noFundsOffer.enabled,
        noFundsOfferEndDate,
        activeProduct?.isOneTimePurchase,
        errorPopup?.enabled,
        dispatch,
        onError,
      ]
    );

    const onActionTokenExchanged = useCallback(
      async (actionToken: { id: any }) => {
        setTokenThreeDSecure(null);

        if (isUpsaleOneTimePayment && oneTimeRecurlyId) {
          try {
            dispatch(setLoading(true));
            const purchaseResult = await dispatch(
              handlePurchaseThunk({ recurlyId: oneTimeRecurlyId })
            );

            if (purchaseResult.meta.requestStatus === "fulfilled") {
              if (isOnlyOneTimePurshaseUpsale) {
                isRedirectToLastScreen
                  ? dispatch(setCurrentStep(steps.length))
                  : dispatch(nextStep());
              }

              if (subscribeRecurlyId) {
                await dispatch(submitCancelSubscription(subscribeRecurlyId));
                await dispatch(
                  subscribe({
                    ...processFormData,
                    price_id: subscribeRecurlyId,
                  })
                ).then(() => {
                  dispatch(nextStep());
                });
                Analytics.trackEvent("step", "completed", {
                  upsaleID: "SKIP_TRIAL",
                  result: "upsale_selected",
                });
              }
            }
          } finally {
            dispatch(setLoading(false));
          }
        } else {
          const userData: UserData = {
            email: paymentData?.email! || email,
            first_name: paymentData?.first_name,
            last_name: paymentData?.last_name,
            settings: {
              paid_status: activeProduct.paid_status!,
              web_questions_answers: selectedAnswers,
            },
          };

          dispatch(
            subscribe({
              ...(paymentData || {}),
              three_d_secure_action_result_token_id: actionToken.id,
            })
          )
            //@ts-ignore
            .then((purchase: Subscription) => {
              handlePaymentSuccess(purchase);
              APP_NAME === "soula" && createUser(userData);
              setPaymentData(null);
            })
            .catch((error: { message: any; code: any }) => {
              toast(error?.message || "3D Secure failed");
              Analytics.trackEvent("ecommerce", "error", {
                message: error?.message || "3D Secure failed",
                code: error?.code,
              });
            });
        }
      },
      [
        isUpsaleOneTimePayment,
        oneTimeRecurlyId,
        dispatch,
        isOnlyOneTimePurshaseUpsale,
        subscribeRecurlyId,
        isRedirectToLastScreen,
        steps.length,
        paymentData,
        email,
        activeProduct.paid_status,
        selectedAnswers,
        handlePaymentSuccess,
      ]
    );

    const onActionTokenError = useCallback(
      (error: RecurlyError) => {
        setTokenThreeDSecure(null);
        handleCardPaymentError({
          message: error.message,
          code: error.code,
          paymentSystem: PaymentSystem.RECURLY,
        });
      },
      [handleCardPaymentError]
    );

    const processFormData = useCallback(
      async (formData: any) => {
        Analytics.trackEvent("modal_payment", "submit", {
          method: formData?.method,
          paymentSystem: formData?.paymentSystem,
          productId: activeProduct?.id,
          value: +activeProduct?.trialPriceAmount || +activeProduct?.amount,
          currency: activeProduct?.currency,
        });
        setPaymentData(formData);

        if (isUpsaleOneTimePayment && oneTimeRecurlyId) {
          try {
            dispatch(setLoading(true));
            const purchaseResult = await dispatch(
              handlePurchaseThunk({ recurlyId: oneTimeRecurlyId })
            );

            if (purchaseResult.meta.requestStatus === "fulfilled") {
              if (isOnlyOneTimePurshaseUpsale) {
                isRedirectToLastScreen
                  ? dispatch(setCurrentStep(steps.length))
                  : dispatch(nextStep());
              }

              if (subscribeRecurlyId) {
                await dispatch(submitCancelSubscription(subscribeRecurlyId));
                await dispatch(
                  subscribe({ ...formData, price_id: subscribeRecurlyId })
                ).then(() => {
                  dispatch(nextStep());
                });
                Analytics.trackEvent("step", "completed", {
                  upsaleID: "SKIP_TRIAL",
                  result: "upsale_selected",
                });
              }
            }
          } finally {
            dispatch(setLoading(false));
          }
        } else {
          const userData: UserData = {
            email: formData?.email! || email,
            first_name: formData?.first_name,
            last_name: formData?.last_name,
            settings: {
              paid_status: activeProduct.paid_status!,
              web_questions_answers: selectedAnswers,
            },
          };

          dispatch(subscribe(formData))
            //@ts-ignore
            .then((purchase: Subscription) => {
              handlePaymentSuccess(purchase);
              APP_NAME === "soula" && createUser(userData);
            })
            .catch((error: PaymentError & { data?: any }) => {
              if (error?.data?.three_d_secure_action_token_id) {
                setTokenThreeDSecure(
                  error?.data?.three_d_secure_action_token_id
                );
              } else {
                handleCardPaymentError(error);
              }
            });
        }
      },
      [
        activeProduct?.amount,
        activeProduct?.currency,
        activeProduct?.id,
        activeProduct?.trialPriceAmount,
        dispatch,
        handleCardPaymentError,
        handlePaymentSuccess,
      ]
    );

    const handleSubmit = useCallback(
      async (formData: any) => {
        const ps = formData.paymentSystem || paymentSystem;
        if (formData.email && formData.email !== email) {
          dispatch(processEmail(formData.email));
        }
        if (formData.email || formData.first_name || formData.last_name) {
          Analytics.trackEvent("user", "info", {
            email: formData.email || undefined,
            first_name: formData.first_name || undefined,
            last_name: formData.last_name || undefined,
          });
        }
        if (ps === PaymentSystem.SOLIDGATE) {
          Analytics.trackEvent("modal_payment", "submit", {
            method: formData?.method,
            paymentSystem: formData?.paymentSystem,
            productId: activeProduct?.id,
            value: +activeProduct?.trialPriceAmount || +activeProduct?.amount,
            currency: activeProduct?.currency,
          });
        }
        await dispatch(
          createCustomer({ email: formData.email, paymentSystem: ps })
        );
        if (ps !== PaymentSystem.SOLIDGATE) {
          await processFormData(formData);
        }
        onSubmit && onSubmit();
      },
      [
        activeProduct?.amount,
        activeProduct?.currency,
        activeProduct?.id,
        activeProduct?.trialPriceAmount,
        dispatch,
        email,
        onSubmit,
        paymentSystem,
        processFormData,
      ]
    );

    useImperativeHandle(ref, () => {
      return {
        showPaymentPopup() {
          setIsOpenPaymentPopup(true);
        },
        closePaymentPopup() {
          setIsOpenPaymentPopup(false);
          if (isInjectedPaymentMethodOnPage) {
            dispatch(setInjectedPaymentMethod(defaultInjectedPaymentMethod));
          }
        },
        processFormData(data: any) {
          processFormData(data);
        },
        subscribeOnOneClick(data: SubscribeOnOneClickParams) {
          socRef.current?.subscribeOnOneClick(data);
        },
      };
    }, [
      defaultInjectedPaymentMethod,
      isInjectedPaymentMethodOnPage,
      dispatch,
      processFormData,
    ]);

    const handleApplePayClick = useCallback(() => {
      Analytics.trackEvent("apple_pay", "click");
    }, []);

    const navigateNextScreen = useNavigateAfterPurchase();
    
    const handleApplePaySubmit = useCallback(
      async (formData: any) => {
        Analytics.trackEvent("apple_pay", "submit");
        await handleSubmit(formData);
        if (process.env.REACT_APP_APPLE_PAY_H2H) {
          const ps = formData.paymentSystem || paymentSystem;
          if (ps === PaymentSystem.SOLIDGATE) {
            dispatch(applePayOrder(formData))
              //@ts-ignore
              .then((purchase: Subscription) => {
                handlePaymentSuccess(purchase);
                navigateNextScreen()
              })
              .catch((error: PaymentError) => {
                handleCardPaymentError(error);
              });
          }
        }
      },
      [
        dispatch,
        handleCardPaymentError,
        handlePaymentSuccess,
        handleSubmit,
        paymentSystem,
      ]
    );

    const handleGooglePayClick = useCallback(() => {
      Analytics.trackEvent("google_pay", "click");
    }, []);

    const handleGooglePaySubmit = useCallback(
      (formData: any) => {
        Analytics.trackEvent("google_pay", "submit");
        return handleSubmit(formData);
      },
      [handleSubmit]
    );

    const handlePayPalSubmit = useCallback(
      (formData: any) => {
        return processFormData(formData);
      },
      [processFormData]
    );

    const handleUserInfoChange = useCallback(
      (info: any) => {
        const em = info?.email;
        if (em && em !== email) {
          dispatch(processEmail(em));
          dispatch(initOrders());
        }
        if (info?.zip) {
          dispatch(setPostCode(info.zip));
        }
      },
      [dispatch, email]
    );

    const handleCardFormSubmit = useCallback(
      (formData: any) => {
        dispatch(setIsSubmitByCard(true));
        Analytics.trackEvent("card", "submit");
        return handleSubmit(formData);
      },
      [dispatch, handleSubmit]
    );

    const handleErrorPopupCancel = useCallback(
      (isPaypal?: boolean) => {
        Analytics.trackEvent(
          "error_popup",
          "cancel",
          isPaypal ? { mode: "paypal" } : undefined
        );
        setIsOpenErrorPopup(false);
        if (isPaypal) {
          setIsOpenPaymentPopup(true);
        } else {
          onClose && onClose();
        }
      },
      [onClose]
    );

    const handleErrorPopupSubmit = useCallback(() => {
      setIsOpenErrorPopup(false);
      Analytics.trackEvent("error_popup", "submit");
      setIsOpenPaymentPopup(true);
    }, []);

    const handleSubscribeOnOneClickError = useCallback(
      (error: PaymentError) => {
        if (onSubscribeOnOneClickError) {
          onSubscribeOnOneClickError(error);
        } else {
          setErrorCode(error?.code);
          setIsOpenErrorPopup(true);
        }
      },
      [onSubscribeOnOneClickError]
    );

    const cardFormProps = useMemo(
      () => ({
        isSimpleView: true,
        product: activeProduct,
        color: "#03BD12",
        options,
        paymentSystem,
        formClassName: "payment-form",
        fieldClassName: classNames("form-field", {
          "form-field-compact": cardFormLayout === CardFormLayout.COMPACT,
        }),
        onSubmit,
        onSuccess,
        onError,
        onApplePayClick: handleApplePayClick,
        onApplePaySubmit: handleApplePaySubmit,
        onGooglePayClick: handleGooglePayClick,
        onGooglePaySubmit: handleGooglePaySubmit,
        onUserInfoChange: handleUserInfoChange,
        onSaveTokenOneTime,
        subscriptionPageType: "cerebrum",
      }),
      [
        // isSimpleView,
        // color,
        activeProduct,
        options,
        cardFormLayout,
        paymentSystem,
        onSubmit,
        onSuccess,
        onError,
        handleApplePayClick,
        handleApplePaySubmit,
        handleGooglePayClick,
        handleGooglePaySubmit,
        handleUserInfoChange,
        onSaveTokenOneTime,
      ]
    );

    const injectedPaymentMethod = useSelector(selectInjectedPaymentMethod);

    const injectedApplePay =
      injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY_GOOGLEPAY ||
      injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY ||
      injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY_PAYPAL;
    const injectedGooglePay =
      injectedPaymentMethod === InjectedPaymentMethod.APPLEPAY_GOOGLEPAY ||
      injectedPaymentMethod === InjectedPaymentMethod.GOOGLEPAY ||
      injectedPaymentMethod === InjectedPaymentMethod.GOOGLEPAY_PAYPAL;
    const isBaseModal = true;
    const hiddenAltMethods =
      isInjectedPaymentMethodOnPage && !activeProduct?.isOneTimePurchase;

    const [height, setHeight] = useState(0);
    const contentRef = useRef<HTMLDivElement>(null);

  const locale = useAppSelector((state) => state.locale.locale);
  const { isOpenErrorPopup: isOpenDetailsErrorPopup } = useAppSelector(state => state.billing)

    const togglePayment = () => {
      dispatch(setIsPaymentOpen(!isPaymentOpen))
      if (!isPaymentOpen && contentRef.current) {
        Analytics.trackEvent("sub", "open_card_form");
        setHeight(contentRef.current.scrollHeight);
      } else {
        Analytics.trackEvent("sub", "card_form_back_tap");
        setHeight(0);
      }
    };

    return (
      <>
        <div style={{ width: "100%", opacity: 1 }}>
          {isLoadingApp || isLoadingBilling ? (
            <Preloader className="PaymentModalPreloader" />
          ) : (
            <Provider>
              {/*@ts-ignore*/}
              <div
                className={classNames(classes.paymentMethods, {
                  [classes.hidden]: hiddenAltMethods,
                })}
              >
                {((showApplePay && injectedApplePay) ||
                  (showApplePay && isBaseModal)) && (
                  <div
                    style={{ marginTop: "16px" }}
                    className={classes.buttonWrapper}
                  >
                    <ApplePay
                      activeProduct={activeProduct}
                      className={classes.appleButton}
                      onApplePayClick={handleApplePayClick}
                      onApplePaySubmit={handleApplePaySubmit}
                    />
                  </div>
                )}
                {
                  <div
                    className={classNames(classes.buttonWrapper, {
                      [classes.hidden]:
                        !showApplePay || !(injectedApplePay || isBaseModal),
                    })}
                  >
                    <div id={options?.applePayButtonParams?.containerId}></div>
                  </div>
                }

                {
                  <div
                    className={classNames(classes.buttonWrapper, {
                      [classes.hidden]: !(injectedGooglePay || isBaseModal),
                    })}
                  >
                    <div id={options?.googlePayButtonParams?.containerId}></div>
                  </div>
                }
              </div>
              {!isPaymentOpen || isOpenDetailsErrorPopup
? (
                <button className={classes.openButton} onClick={togglePayment}>
                  <CreditCardIcon />
                  <div>{cardButton[locale]}</div>
                </button>
              )
: (
                <button onClick={togglePayment} className={classes.closeButton}>
                  <LeftArrowIcon />
                </button>
              )}
              <div
                ref={contentRef}
                style={{
                  height: isPaymentOpen ? `auto` : "0px",
                  overflow: "hidden",
                  transition: "height 0.4s ease-in-out",
                  width: "100%",
                }}
              >
                {/*@ts-ignore*/}
                <PaymentForm {...cardFormProps} />
              </div>
            </Provider>
          )}

          {tokenThreeDSecure && (
            <ThreeDSecure
              actionTokenId={tokenThreeDSecure}
              onToken={onActionTokenExchanged}
              onError={onActionTokenError}
            />
          )}

          <SubscribeOnOneClick
            ref={socRef}
            onSuccess={onSuccess}
            onError={handleSubscribeOnOneClickError}
          />

          {errorPopup?.enabled && (
            <ErrorPopup
              product={activeProduct}
              onClose={handleErrorPopupCancel}
              visible={isOpenErrorPopup}
              withPayPal={errorPopupWithPayPal}
              onPayPalSubmit={handlePayPalSubmit}
              onPayPalSuccess={handleCardPaymentSuccess}
              onPayPalError={handleCardPaymentError}
              onSubmit={handleErrorPopupSubmit}
              buttonTitle={errorPopup.buttonTitle}
              statusCode={errorCode}
            />
          )}

          {!!isPending && <Preloader className="PaymentModalPreloader" />}
        </div>
      </>
    );
  }
);
