//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { Flex, Slider, Switch, Typography } from 'antd';
import i18next from 'i18next';

import { T } from "@web-solutions/module-localization";

import { useAppSelector } from "@web-solutions/store/types"


import { ProductDetails } from "core/store/billing/selectors";
import { Block } from "core/ui-elements";
import { StandalonePayment, StandalonePaymentRef, } from 'core/payment/standalone';

import { useRemoteConfig } from "core/hooks/use-remote-config";

import detailedClasses from './detailed.module.scss';
import simpleClasses from './simple.module.scss';
import commonClasses from './style.module.scss';

import { ReactComponent as CheckIcon } from './icons/check.svg';
import classNames from "classnames";

const generateLinkComponents = (links: Record<string, string>) => {
  return Object.keys(links).reduce((acc, key, index) => {
    acc[key] = (
      <a href={links[key]} target="_blank" rel="noopener noreferrer">
        {key}
      </a>
    );
    return acc;
  }, {} as Record<number, React.ReactNode>);
};

interface SinglePlanProps {
  product: ProductDetails,
}

export const StandalonePlan: React.FC<SinglePlanProps> = ({ product }) => {
  const [expanded, setExpanded] = useState(false);
  const standalonePaymentRef = useRef<StandalonePaymentRef>(null);
  const locale = useAppSelector((state) => state.locale.locale);

  const { cerebrumSubScreenData, cerebrumPaymentTextVariant, totalBlockDetailed } = useRemoteConfig();
  const { textsWidthCheck, totalTitle, hint, hintTitle, featureBlock, includedFeatures, supportEmail = '' } = cerebrumSubScreenData.standalonePlan
  const { policyLinks } = useRemoteConfig()
  const classes = totalBlockDetailed ? detailedClasses : simpleClasses

  const Hint = () => {
    return <>
      {hintTitle && <p className={classes.hintTitle}>
        <T
          k={hintTitle[locale]}
          tOptions={{ supportEmail, ...product }}
          components={{
            supportEmailLink: <a href={`mailto:${supportEmail}`} />,
            ...generateLinkComponents(policyLinks)
          }}
        />
      </p>}
      {hint && <p className={classes.hintText}>
        <T
          k={hint[locale]}
          tOptions={{ supportEmail, ...product }}
          components={{
            supportEmailLink: <a href={`mailto:${supportEmail}`} />,
            ...generateLinkComponents(policyLinks)
          }}
        />
      </p>}
    </>
  }

  const CheckSection = () => {
    return <>
      {textsWidthCheck[locale]?.map((text, index) => (
        <div id={index === textsWidthCheck[locale]?.length - 2 ? "total-section" : ""}
          className={commonClasses.item}
          key={index}
        >
          <CheckIcon />
          <p className={commonClasses.text}>{text}</p>
        </div>
      ))}
    </>
  }

  const FeatureSection = () => {
    return <>
      {featureBlock[locale]?.map(({ emoji, title, description }) => (
        <div className={commonClasses.featureBlock} key={title}>
          <span className={commonClasses.emoji}>{emoji}</span>
          <div>
            <p className={commonClasses.featureTitle}>{title}</p>
            <p className={commonClasses.featureDescription}>{description}</p>
          </div>
        </div>
      ))}
    </>
  }

  const TotalSection = () => {
    return <>
      {
        totalBlockDetailed ? <>
          <div className={classes.totalSection}>
            <h3 className={classes.totalTitle}>{totalTitle[locale]}</h3>
            <div className={classes.priceDiscount}>
              {/* <span className={classes.priceDiscount}>{product.textLineThrough}</span> */}
              <span>${Boolean(product.trialDays) ? product.trialPriceAmount : product.amount}</span>
              <div>({product.badgeTitle[locale]})</div>
            </div>
          </div>

          <div className={classes.includedFeatures}>
            {
              includedFeatures.map(i => (
                <div className={classes.includedFeature}>
                  <p className={classes.text}>{i.text[locale]}</p>
                  <div className={classes.price}>
                    <p className={classes.priceLineThroughVal}>{i.priceLineThrough}</p>
                    <p className={classes.priceVal}>{i.price}</p>
                  </div>
                </div>
              ))
            }
          </div>
        </>
          : <>
            <div className={classes.totalSection}>
              <h3 className={classes.totalTitle}>{totalTitle[locale]}</h3>
              <div>
                <span className={classes.priceDiscount}>{product.textLineThrough}</span>
                <span className={classes.totalTitle}>${Boolean(product.trialDays) ? product.trialPriceAmount : product.amount}</span>
                <div className={classes.discountHint}>{product.badgeTitle[locale]}</div>
              </div>
            </div>
            <div className={classes.hint}>
              <Hint />
            </div>
          </>
      }
    </>
  }

  return (
    <Block className={classNames(commonClasses.block)}>
      <CheckSection />
      <TotalSection />


      <FeatureSection />

      <StandalonePayment
        ref={standalonePaymentRef}
        activeProduct={product}
      />

      {totalBlockDetailed && <Typography.Paragraph
        style={{ marginBottom: 0 }}
        className={classes.hint}
        ellipsis={cerebrumPaymentTextVariant === 'COLLAPSE' ? {
          rows: 2,
          expandable: 'collapsible',
          expanded,
          onExpand: (_, info) => setExpanded(info.expanded),
          symbol: (expanded) => <span className={classes.expandedBtn}>{expanded ? 'less' : 'more'}</span>
        } : false}
      >
        <Hint />
      </Typography.Paragraph>}
    </Block>
  )
}







