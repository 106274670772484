import { localizedString, LocalizedString } from "core/localization/constants/remote-config"

export interface RemoteConfigCorrectPage {
  correctScreen: {
    title: LocalizedString
    text: LocalizedString[]
    button: LocalizedString
  }
}

export const INITIAL_CONFIG_CORRECT: RemoteConfigCorrectPage = {
  correctScreen: {
    title: localizedString('Result of IQ Test'),
    text: [
      localizedString('Your IQ is not within the current range of our test.'),
      localizedString('You can <b>try again carefully</b>, and we will male a more accurate assessment.')
    ],
    button: localizedString('Try Again')
  }
}