import { ImageSource } from "core/interfaces/images";

export interface LocalizedString {
  en: string;
  es: string;
  fr: string;
  pt: string;
  de: string;
  tr: string;
  it: string;
  cs: string;
  ro: string;
  pl: string;
  id: string;
  ar: string;
}

export const localizedString = (val: string): LocalizedString => ({
  en: val,
  es: '',
  fr: '',
  pt: '',
  de: '',
  tr: '',
  it: '',
  cs: '',
  ro: '',
  pl: '',
  id: '',
  ar: '',
})

export interface LocalizedSupported {
  en: "English";
  es: "Spanish";
  fr: "French";
  pt: "Portuguese";
  de: "German";
  tr: "Turkish";
  it: "Italian";
  cs: "Czech";
  ro: "Romanian";
  pl: "Polish";
  id: "Indonesian";
  ar: "Arabic";
}

export interface LocalizedArray {
  en: string[];
  es: string[];
  fr: string[];
  pt: string[];
  de: string[];
  tr: string[];
  it: string[];
  cs: string[];
  ro: string[];
  pl: string[];
  id: string[];
  ar: string[];
}

export const localizedArray = (val: string[]): LocalizedArray => {
  const defaultVal = val.map((_) => '')

  return ({
    en: val,
    es: defaultVal,
    fr: defaultVal,
    pt: defaultVal,
    de: defaultVal,
    tr: defaultVal,
    it: defaultVal,
    cs: defaultVal,
    ro: defaultVal,
    pl: defaultVal,
    id: defaultVal,
    ar: defaultVal,
  })
}

export interface LocalizedImage {
  en: ImageSource;
  es: ImageSource;
  fr: ImageSource;
  pt: ImageSource;
  de: ImageSource;
  tr: ImageSource;
  it: ImageSource;
  cs: ImageSource;
  ro: ImageSource;
  pl: ImageSource;
  id: ImageSource;
  ar: ImageSource;
}

export interface LocalizedDynamic<T> {
  en: T;
  es: T;
  fr: T;
  pt: T;
  de: T;
  tr: T;
  it: T;
  cs: T;
  ro: T;
  pl: T;
  id: T;
  ar: T;
}

export interface RemoteConfigLocales {
  localizationEnabled: boolean;
  supportedLanguages: LocalizedSupported;
}

export const INITIAL_CONFIG_LOCALES: RemoteConfigLocales = {
  localizationEnabled: false,
  supportedLanguages: {} as LocalizedSupported,
};
