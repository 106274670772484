import { Result } from "core/containers/test-results"
import { LocalizedArray, localizedArray, localizedString, LocalizedString } from "core/localization/constants/remote-config"

export interface RemoteConfigWelcomeDetailedPage {
  quizWelcomeDetailedPage: {
    button: LocalizedString,

    main: {
      title: LocalizedString,
      subtitle: LocalizedString,
    }

    about: {
      text: LocalizedString,
      list: {
        amount: string,
        text: LocalizedString,
      }[]
    }

    advantages: {
      title: LocalizedString,
      list: {
        title: LocalizedString,
        text: LocalizedString,
      }[]
    }

    features: {
      title: LocalizedString,
      list: {
        title: LocalizedArray
        text: LocalizedString
      }[],
    }

    discover: {
      title: LocalizedString
    }

    results: {
      title: LocalizedString,
      list: Result[]
    }
  }
}

export const INITIAL_CONFIG_WELCOME_DETAILED_PAGE: RemoteConfigWelcomeDetailedPage = {
  quizWelcomeDetailedPage: {
    main: {
      title: localizedString('Uncover <b>your true IQ</b> in just 3 minutes'),
      subtitle: localizedString('The average IQ in Belgium is 87')
    },

    button: localizedString('Start Certified Test'),

    about: {
      text: localizedString("Our test is designed using the latest cognitive research and delivers results comparable to official IQ assessments."),
      list: [
        {
          amount: "37,169",
          text: localizedString("<b>{{amount}}</b> tests completed today"),
        },
        {
          amount: "99",
          text: localizedString("Avg. IQ score: <b>{{amount}}</b>"),
        },
        {
          amount: "93.7%",
          text: localizedString("<b>{{amount}}</b> of results rated as reliable"),
        }
      ]
    },

    advantages: {
      title: localizedString('Why take this IQ test?'),
      list: [
        {
          title: localizedString("Cognitive Flexibility"),
          text: localizedString("The ability to adapt to new information, switch tasks, and see from multiple perspectives."),
        },
        {
          title: localizedString("Numerical Aptitude"),
          text: localizedString("The ability to process data, recognize patterns, and solve math problems quickly."),
        },
        {
          title: localizedString("Analytical Reasoning"),
          text: localizedString("The capacity to evaluate data logically, make decisions, and solve abstract problems."),
        },
        {
          title: localizedString("Information Structuring"),
          text: localizedString("The ability to sort, classify, and interpret data to uncover meaningful insights."),
        }
      ]
    },

    features: {
      title: localizedString("All the Features of an IQ Test"),
      list: [
        {
          title: localizedArray(["UNLOCK", "your intelligence"]),
          text: localizedString("Get your precise IQ score along with a detailed breakdown by cognitive abilities. Compare your results with others and see where you stand on the IQ distribution curve.")
        },
        {
          title: localizedArray(["VALIDATE", "your IQ"]),
          text: localizedString("Obtain a personalized IQ certificate to showcase your cognitive abilities. Share your score with family, friends, or colleagues!")
        },
        {
          title: localizedArray(["IMPROVE", "your intelligence"]),
          text: localizedString("Our team combines the latest scientific research with interactive lessons and brain games. Engage in a mix of educational content and stimulating challenges designed to sharpen your cognitive abilities and boost your overall performance.")
        }
      ]
    },

    discover: {
      title: localizedString("Discover your accurate <b>IQ score</b>")
    },

    results: {
      title: localizedString("Latest brain test results"),
      list: [
        {
          name: 'Anabell',
          time: localizedString('5 minutes ago'),
          iq: 103
        },
        {
          name: 'Jessica',
          time: localizedString('31 minutes ago'),
          iq: 117
        },
        {
          name: 'Thomas',
          time: localizedString('14 minutes ago'),
          iq: 101
        },
        {
          name: 'Monica',
          time: localizedString('48 minutes ago'),
          iq: 93
        }
      ]
    }
  }
}
